
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const login = Yup.object().shape({
      //email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(1).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = (values) => {
      // Clear existing errors
      store.dispatch(Actions.LOGOUT);

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      //Dummy delay
      setTimeout(() => {
        // Send login request
        store.dispatch(Actions.LOGIN, values).then((response) => {
          console.log(response)
          switch (response.status) {
            case 200:
              console.log("Login success");
              //INITIAL LOAD
              //store.dispatch(Actions.LOAD_PRODUCTS);
              //store.dispatch(Actions.LOAD_DASH_INVOICES);
              //store.dispatch(Actions.LOAD_INDEBT);
              //store.dispatch(Actions.LOAD_DASH_INVOICES);
              //store.dispatch(Actions.LOAD_NOTIFICATIONS);

              // Go to page after successfully login
              router.push({ name: "dashboard" });

              break;
            // case 401:
            //   Swal.fire({
            //     text: "Credenciais inválidas",
            //     icon: "error",
            //     buttonsStyling: false,
            //     confirmButtonText: "Tente novamente!",
            //     customClass: {
            //       confirmButton: "btn fw-bold btn-light-danger",
            //     },
            //   });
            //   break;
            default:
              //Deactivate indicator
              submitButton.value?.removeAttribute("data-kt-indicator");
              // eslint-disable-next-line
              submitButton.value!.disabled = false;
              if (response.data && response.data['Message'] == "Sem Acessos") {
                Swal.fire({
                  title: "Sem Acessos",
                  text: "Sem acessos às áreas financeira e comercial.\nPara mais informações consulte: compras@ferragensdomarques.pt",
                  icon: "warning",
                  buttonsStyling: false,
                  confirmButtonText: "Tente mais tarde",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-warning",
                  },
                });
              } else {
                Swal.fire({
                  text: "Credenciais inválidas",
                  icon: "error",
                  buttonsStyling: false,
                  confirmButtonText: "Tente novamente!",
                  customClass: {
                    confirmButton: "btn fw-bold btn-light-danger",
                  },
                });
              }
              break;
          }
        });
      }, 3000);
    };

    return {
      onSubmitLogin,
      login,
      submitButton,
    };
  },
});
